const BannerEmpty = ({ isPortrait }: { isPortrait: boolean }) => {
  if (isPortrait) {
    return (
      <img
        src="/images/status-board-empty-state-vertical.png"
        alt="Upsell QR code to promote downloading the app."
        className="h-[100vh] w-full"
      />
    );
  }

  return (
    <img
      src="/images/status-board-empty-state-horizontal.png"
      alt="Upsell QR code to promote downloading the app."
      className="w-auto h-[100vh]"
    />
  );
};

export default BannerEmpty;

const Banner = ({ isPortrait }: { isPortrait: boolean }) => {
  const url = isPortrait
    ? "/images/status-board-horizontal.png"
    : "/images/status-board-vertical.png";

  const className = isPortrait
    ? "w-full h-auto border-t-[1px] border-solid border-black"
    : "h-full w-auto border-r-[1px] border-solid border-black";
  return (
    <img
      src={url}
      alt="Upsell QR code to promote downloading the app."
      className={className}
    />
  );
};

export default Banner;
